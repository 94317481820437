import React, {useEffect, useState} from 'react';
import {decrypt, readMessage, readPrivateKey} from "openpgp";

const PRIVATE_KEY = `-----BEGIN PGP PRIVATE KEY BLOCK-----

xVgEY87ATRYJKwYBBAHaRw8BAQdApym6GH4J9BxqLnVcP+N6loXWMB425r+8
HcYVlEvhnuYAAQCGCz1AkILpB+NS2Lwi//Zlr8efhE2G6Wy49Xa0E+zsixJk
zRtKb24gU21pdGggPGpvbkBleGFtcGxlLmNvbT7CjAQQFgoAPgUCY87ATQQL
CQcICRD+ffla6k+UIgMVCAoEFgACAQIZAQIbAwIeARYhBI7H6EizPeHEVWKG
/v59+VrqT5QiAAAChAEA2fUOSQsohO6EZu1Lnvj8w5i+OA6bxDad0qOMi/7U
3YkBAJj2Bj0oxLM8zlxu09TPROtYz2UDr3TkCkYu938Cdp0Lx10EY87ATRIK
KwYBBAGXVQEFAQEHQGRseaUMY08eDmjl11Uahi1y1cRkpvUGy85UAaGEJNEf
AwEIBwAA/0ySQsuN/8oDCWcP0f40L/ktLNdCHJkklBF3Las88v+4EBHCeAQY
FggAKgUCY87ATQkQ/n35WupPlCICGwwWIQSOx+hIsz3hxFVihv7+ffla6k+U
IgAAU8kBAN4hUlYWT+6t8A4cQiJkNO/UORkQuOWr/TqGTuk7KPiOAQDfcOn/
hkdX4APtME4ZIr9CgoCzVfT7EGzoyTTRboclDA==
=tT7e
-----END PGP PRIVATE KEY BLOCK-----`;

const Shihs = () => {
    const [data, setData] = useState(false);
    useEffect(()=>{
        fetch(
            "https://testnet.polybase.xyz/v0/collections/pk%2F0x9c8bdd76b35e896750fbc92348131c33db40ba415ae11d86a7fb8a53840c374fac1e044c5839e146f6243d477b81bb8bccaccff0c0241b95705fb9fb48252d1b%2Fpic-share-col%2Fpics/records/1686568838707b4b2930a-dc30-4263-9edc-08391cb0f252"
        ,  {method:"GET"}
        ).then(async r => {
            console.log(await r.json())
            // setData((await r.json()).data[90])
        })
    },[])

    useEffect(()=>{
        if(data){
             decode(data.data.data).then(r=>{
                 console.log(r)
             })
        }
    },[data])

    const decode = async (string) => {
        if (string.includes("-----BEGIN PGP MESSAGE-----")) {
            try {
                let privateKey = await readPrivateKey({ armoredKey: `${PRIVATE_KEY}` })
                const message = await readMessage({
                    armoredMessage: `${string}`,
                });
                try {
                    const {data: decrypted} = await decrypt({
                        message,
                        decryptionKeys: privateKey,
                    });
                    return decrypted;
                } catch (e) {
                    console.log(e)
                    return false;
                }
            } catch (e) {
                console.log(e)

                return false;
            }
        } else {
            return string;
        }
    };


    return (
        <div>
            
        </div>
    );
};

export default Shihs;