import React from 'react';
// type may be cloud or descr
const SectionTitle = ({icon, text, optional = false , hide}) => {
    return (
        <div className={`title ${hide && "hide"}`}>
            {icon && icon}
            <div>
                {text}
                {optional &&<small>(optional)</small>}
            </div>
        </div>
    );
};

export default SectionTitle;