import {S3Client} from "@aws-sdk/client-s3";
import {createPresignedPost} from "@aws-sdk/s3-presigned-post";
import fetchRetry from "./fetchRetry";

const clientData = {
    credentials: {
        accessKeyId: "Q1MZIWL8P5AI9NIN58AW",
        secretAccessKey: "T5fTHn3AX1A2rsoaFV9alLwZTgbVUE9kO5XM8d41"
    },
    region: "us-east-1",
    endpoint: "https://ams1.vultrobjects.com/",
    forcePathStyle: true,
    systemClockOffset:3600,
};


const createPresignedPostForUploadAnObject = async (clientData, bucket, key, filetype) => {
    const client = new S3Client(clientData);
    console.log(client.config);
    // const command = new PutObjectCommand({Bucket: bucket, Key: key, ACL: "public-read-write"});
    let presignedPost = await createPresignedPost(
        client,
        {
            Bucket: bucket,
            Key: key,
            Conditions: [
                ["content-length-range", 0, 52428800], // up to 50 MB
            ],
            Fields: {
                acl: "public-read",
                key: key,
                "Content-Type": filetype,
            },
            Expires:3600*24*7,
        }
    )
    return presignedPost;
};

const uploadFile = async (file) => {
    let success = true;

    let {url, fields} = await createPresignedPostForUploadAnObject(clientData, "img", file.newName, file.file.type)

    const formData = new FormData();
    file = file.file
    Object.entries({...fields, file}).forEach(([key, value]) => {
        formData.append(key, value);
    });
    let api_url = "https://pic-share.top/s3/";
    // await wait(getRandomTime())
    console.log("Image #"+file.title+" optimized")
    let r = await fetchRetry(api_url, 800, 3, {method: "POST", body: formData}, true).catch(() => {
        console.log("ERROR")
        success = false
    })
    console.log(r.status)
    if(r.status !== 200 && r.status !== 204){
        success = false
    }
    console.log(r)
    return success;
}
function getRandomTime() {
    const min = 0;
    const max = 10;
    const step = 0.5;
    const randomSeconds = Math.floor(Math.random() * ((max - min) / step + 1)) * step;
    return randomSeconds * 1000; // Конвертируем в миллисекунды
}
const wait = (delay) =>{
    return new Promise((resolve) => setTimeout(resolve, delay));
}

export default uploadFile;
