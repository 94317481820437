const  fetchRetry = (url, delay, tries, fetchOptions = {}, needSignal = false, signalTM = 10000)=> {

    function onError(err){
        let triesLeft = tries - 1;
        if(!triesLeft){
            throw err;
        }
        return wait(delay).then(() => fetchRetry(url, delay, triesLeft, fetchOptions));
    }


    return fetch(url, fetchOptions).catch(onError);
}
const wait = (delay) =>{
    return new Promise((resolve) => setTimeout(resolve, delay));
}

export default fetchRetry